import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import ogpImage from '../../../static/ogp.jpg';
import twitterImage from '../../../static/twitter_card.jpg';

type Seo = {
  title: String;
  description: string;
  lang: string;
  meta:
    | {
        name: string;
        content: string;
      }
    | {
        property: string;
        content: string;
      }[];
};

function Seo({ title, description, lang, meta }: Seo) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = title || site.siteMetadata?.title;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={defaultTitle}
      titleTemplate={defaultTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: defaultTitle
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:image`,
          content: site.siteMetadata.siteUrl + ogpImage
        },
        {
          property: `og:image:alt`,
          content: defaultTitle
        },
        {
          property: `og:locale`,
          content: 'ja_JP'
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary`
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``
        },
        {
          name: `twitter:title`,
          content: defaultTitle
        },
        {
          name: `twitter:description`,
          content: metaDescription
        },
        {
          name: `twitter:image`,
          content: site.siteMetadata.siteUrl + twitterImage
        }
      ].concat(meta)}
    />
  );
}

Seo.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object)
};

export default Seo;
